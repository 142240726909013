import { Utilities } from 'src/app/core/utilities';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  constructor(private httpClient: HttpClient) { }

  utilities = new Utilities();

  uploadFile(file: File): Observable<string> {
    if (!file) {
      return of('');
    }

    return this.getPresignedUrl(file).pipe(
      mergeMap(url => {
        const newURL = url.body.substring(1, url.body.length - 1);
        return this.uploadToPresignedUrl(newURL, file.type, file).pipe(map(_ => newURL.split('?')[0]));
      })
    );
  }

  private getPresignedUrl(file: File): Observable<any> {
    const route = 'https://0sz2cejc5f.execute-api.us-east-1.amazonaws.com/dev/mentorship-service/upload/presigned-url';
    const fileName = file.name.replace(/(.*)(?=\.\w*$)/gi, '').toLowerCase();
    const body = {
      contentType: file.type,
      objectKey: this.utilities.uuidV4() + fileName,
      "s3Action": "putObject",
    };
    return this.httpClient.post<any>(route, body) as Observable<any>;
  }

  private uploadToPresignedUrl(url: string, contentType: string, file: File): Observable<Object> {
    const headers = new HttpHeaders({
      'x-amz-acl': 'public-read',
      'content-type': contentType,
    });
    return this.httpClient.put(url, file, { headers });
  }
}

import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  constructor(private http: HttpClient) { }

  public downloadFile(url: string) {
    const filename = url.substring(url.lastIndexOf('/') + 1);
    url = encodeURI(url);
    const headers = new HttpHeaders({
      Accept: 'application/json',
      responseType: 'blob'
    });
    return this.http.get(url, { headers: headers, responseType: 'blob' }).subscribe((data: any) => {
      this.downloadStream(data, filename);
      // this.downloadBlob(new Blob([data]));
    });
  }

  private downloadStream(response: any, name: string) {
    const blob = new Blob([response, { type: response.type }]);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${name}`);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link.href);
    return response;
  }

  private downloadBlob(blob: Blob, filename: string = 'download') {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        this.removeEventListener(a, 'click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();

    return a;
  }

  private removeEventListener(element: HTMLElement, eventName: string, handler: any) {
    element.removeEventListener(eventName, handler);
  }
}

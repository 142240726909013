import { isPlatformBrowser } from '@angular/common';
import { AfterViewChecked, Directive, ElementRef, OnInit, Inject, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[lfxEllipsis]'
})
export class EllipsisDirective implements OnInit, AfterViewChecked {
  private get hasOverflow(): boolean {
    const el: HTMLElement = this.el.nativeElement;
    return el.offsetWidth < el.scrollWidth;
  }

  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  ngOnInit() {
    // class ellipsis: text-overflow: ellipsis; overflow: hidden; white-space: nowrap;
    this.el.nativeElement.classList.add('ellipsis');
  }

  ngAfterViewChecked() {
    const isBrowser = isPlatformBrowser(this.platformId);
    if (isBrowser) {
      this.el.nativeElement.setAttribute('title', this.el.nativeElement.innerText);
    }
  }
}
